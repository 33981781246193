import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  Input,
  InputLabel,
  TextField,
  InputAdornment,
  FormControl,
  IconButton,
  Modal,
  Menu,
  MenuItem,
  Avatar,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Mask from '../../Assets/ProfileImg/Mask.png';
import mainLogo from '../../Assets/Header/mainLogo.svg';
import closeicon from '../../Assets/ProfileImg/closeicon.png';
import pen from '../../Assets/ProfileImg/pen.png';
import walletimg from '../../Assets/ProfileImg/walletimg.svg';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import trash from '../../Assets/ProfileImg/trash.png';
import lock from '../../Assets/LoginSignup/lock.png';
import googleimg from '../../Assets/LoginSignup/googleimg.png';
import user from '../../Assets/LoginSignup/user.png';
import edit from '../../Assets/ProfileImg/edit.png';
import add from '../../Assets/ProfileImg/add.png';
import question from '../../Assets/ProfileImg/question.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import sms from '../../Assets/LoginSignup/sms.png';
import userBoxImg from '../../Assets/ProfileImg/userBoxImg.svg';
import upArr from '../../Assets/ProfileImg/upArr.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderPhone from '../HeaderUi/HeaderPhone';
import Header from '../HeaderUi/Header';
import * as Styles from '../../Common/Styles.js';
import cityimg from '../../Assets/LoginSignup/cityimg.svg';
import profimg from '../../Assets/LoginSignup/profimg.svg';
import QRcode from '../../Assets/Homeimg/QRcode.png';
import QRCodeStaticScanner from './QRCodeStaticScanner.jsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  stringAvatar,
  stringToColor,
} from '../../Components/avatar/stringAvatar';
import {
  useGetUserProfile,
  useUpdateUserProfile,
  useGetBatchList,
  useCreateBatch,
  useUpdateBatch,
  useDeleteBatch,
  useDeleteBatchQuestion,
  GetQuestionsById,
} from '../../Hooks/ProfileHooks';
import { toast } from 'react-toastify';
import { useGetWallet, useGetWalletHistroy } from '../../Hooks/wallet.hooks.js';
const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTypes, setDataTypes] = useState('UpdateProfile');
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [editMode, setEditMode] = useState({
    fullName: true,
    email: false,
    password: false,
    mobile: true,
    city: true,
    profession: true,
  });
  useEffect(() => {
    if (location.state && location.state.activeTab !== undefined) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);
  const { mutate: deleteQuestion } = useDeleteBatchQuestion();
  const [editingItem, setEditingItem] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [batchId, setBatchId] = useState('');
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const {
    data: walletData,
    isLoading: walletLoading,
    error: walletError,
    refetch: walletDataRefetch,
  } = useGetWallet();

  console.log('walletData', walletData);

  const {
    data: walletHistoryData,
    isLoading: walletHistoryLoading,
    error: walletHistoryError,
    refetch: walletHistoryDataRefetch,
  } = useGetWalletHistroy();
  console.log('walletHistoryData', walletHistoryData);

  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const handleOpen = () => {
    setOpen(true);
    setBatchName('Batch Name'); // Reset to default batch name
    setTodoList([]); // Clear the list of questions
    setInputText(''); // Clear the input field
    setIsEditing(false); // Ensure we're not in editing mode
    setEditingItem(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { data: getQuestions, refetch: queRefetch } = GetQuestionsById(batchId);
  const [openEditDelete, setOpenEditDelete] = useState(false);
  const handleOpenEditDelete = (id) => {
    setEditingItem(id?._id);
    setOpenEditDelete(true);

    const selectedBatch = batchList?.data?.data?.find(
      (batch) => batch._id === id
    );
    if (selectedBatch) {
      setEditingItem(selectedBatch);
      setBatchName(selectedBatch.name);
      // setTodoList(selectedBatch.questions || []);
      setBatchId(selectedBatch?._id);
    }
  };
  const handleCloseEditDelete = () => {
    setOpenEditDelete(false);
  };
  const [inputText, setInputText] = useState('');
  const [todoList, setTodoList] = useState([]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleAddItem = () => {
    if (inputText.trim() !== '') {
      setTodoList([...todoList, inputText]);
      setInputText('');
    }
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDeleteItem = (index) => {
    setItemToDelete(index);
    setOpenConfirmDialog(true);
  };
  const confirmDelete = () => {
    deleteBatchMutate(
      { id: itemToDelete },
      {
        onSuccess: () => {
          toast.success('Deleted Successfully');
          batchListRefetch();
          setOpenConfirmDialog(false);
        },
      }
    );
  };
  const modalStyle = {
    position: 'absolute',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #19A8FC',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: '60%',
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [batchName, setBatchName] = useState('Batch Name');

  const handleEditClick = (event) => {
    setIsEditing(!isEditing);
    setEditingItem({ ...editingItem, name: event.target.value });
    setBatchName('');
  };

  const handleInputChangeEdit = (e) => {
    setBatchName(e.target.value);
  };

  const handleSaveClick = () => {
    // You can implement the logic to save the edited batch name here
    setIsEditing(false);
  };
  const [isEditingList, setIsEditingList] = useState(false);
  const [batchNameList, setBatchNameList] = useState('Batch-01');

  const handleEditClickList = () => {
    setIsEditingList(!isEditingList);
  };

  const handleInputChangeEditList = (e) => {
    setBatchNameList(e.target.value);
  };

  const handleSaveClickList = () => {
    // You can implement the logic to save the edited batch name here
    setIsEditingList(false);
    handleCloseEdit();
    handleCloseEditDelete();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openList = Boolean(anchorEl);
  const handleClickEdit = (e, item) => {
    setAnchorEl(e.currentTarget);
    setEditingItem(item);
  };
  const handleCloseEdit = () => {
    setAnchorEl(null);
  };
  const options = ['Edit', 'Delete'];
  const [editIndex, setEditIndex] = useState(null);
  const [editedText, setEditedText] = useState('');

  const handleEditClickBatch = (index) => {
    setEditIndex(index);
    setEditedText(todoList[index]);
  };

  const handleSaveClickBatch = (index) => {
    const updatedList = [...todoList];
    updatedList[index] = editedText;
    setTodoList(updatedList);
    setEditIndex(null); // Reset edit mode
  };

  const handleDeleteItemBatch = (index) => {
    const updatedList = [...todoList];
    updatedList.splice(index, 1);
    setTodoList(updatedList);
  };

  // Get Users Profile Data
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  console.log('kjfkfkjsdfkjsdfjsdf', profileData);

  // batch list

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update Profile

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        fullName: z.string().optional(),
        email: z.string().email().optional(),
        mobile: z.string().optional(),
        city: z.string().optional(),
        profession: z.string().optional(),
        password: z.string().min(8).optional(),
      })
    ),
  });
  const {
    mutate: updateProfile,
    isLoading: updateProfileList,
    isError: updateProfileError,
  } = useUpdateUserProfile();

  const updateProfileUser = handleSubmit((data) => {
    updateProfile(
      { fullName: data.fullName, mobile: data.mobile },
      {
        onSuccess: (response) => {
          toast.success('Update profile successfully !');
          setEditMode({ fullName: true, mobile: true });
          profileDataRefetch();
        },
        onError: (error) => {
          console.log('error', error);
        },
      }
    );
  });

  const {
    data: batchList,
    isLoading: batchListLoading,
    error: batchListError,
    refetch: batchListRefetch,
  } = useGetBatchList();

  const handleDeleteItemBatchQuestion = async (idx, item, id) => {
    const data = {
      id: id,
      questionName: item,
      indexNo: idx,
    };
    let confirm = window.confirm('Are you want to delete this question ?');

    if (confirm) {
      await deleteQuestion(data, {
        onSuccess: () => {
          toast.success('Delete Question Successfully..');
          queRefetch();
          batchListRefetch();
        },
      });
    }
  };

  const {
    mutate: createBatchMutate,
    isLoading: createBatchLoading,
    error,
  } = useCreateBatch();

  const {
    mutate: updateBatchMutate,
    isLoading: updateBatchLoading,
    isError: updateBatchError,
  } = useUpdateBatch();

  const {
    mutate: deleteBatchMutate,
    isLoading: deleteBatchLoading,
    isError: deleteBatchError,
  } = useDeleteBatch();

  const {
    register: batchUpdateRegister,
    handleSubmit: batchUpdateHandleSubmit,
    setError: batchUpdateSetError,
    setValue: batchUpdateSetValue,
    formState: { errors: batchUpdateErrors },
  } = useForm({});

  const batchUpdateSubmit = batchUpdateHandleSubmit((data) => {
    updateBatchMutate(
      { name: batchName, id: editingItem?._id },
      {
        onSuccess: (response) => {
          batchListRefetch();
          toast.success('Updated Successfully');
          handleCloseEditDelete();
          setTodoList([]);
        },
        onError: (error) => {
          console.log('error', error);
        },
      }
    );
  });

  const {
    register: batchRegister,
    handleSubmit: batchHandleSubmit,
    setError: batchSetError,
    setValue: batchSetValue,
    formState: { errors: batchErrors },
  } = useForm({
    // resolver: zodResolver(
    //   z.object({
    //     name : z.string().min(3).optional(),
    //     questions: z.string().questions().optional(),
    //   })
    // ),
  });

  const batchSubmit = batchHandleSubmit((data) => {
    createBatchMutate(
      { name: batchName, questions: todoList },
      {
        onSuccess: (response) => {
          batchListRefetch();
          toast.success('Batch and Questions Added Successfully');
          handleClose();
          setTodoList([]);
        },
        onError: (error) => {
          console.log('error', error);
        },
      }
    );
  });

  // for input Box Glow on Click

  const [isInputFocused, setIsInputFocused] = useState(false);
  const handleInputFocus = () => {
    setIsInputFocused(true);
  };
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const profileDataRender = () => {
    return (
      <form onSubmit={updateProfileUser}>
        <Box
          sx={{
            width: { xl: '60%', lg: '60%', md: '80%', sm: '90%', xs: '90%' },
            mx: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={subText}>Full Name</Typography>
              <Box
                component={'img'}
                src={edit}
                alt="img"
                onClick={() => {
                  setEditMode({ ...editMode, fullName: !editMode.fullName });
                }}
                sx={editDes}
              />
            </Box>
            {editMode.fullName ? (
              <>
                <Box
                  id="standard-basic"
                  variant="outlined"
                  disableUnderline
                  sx={{
                    ...EditInputStyle,
                    border: `2px solid ${
                      isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                    }`,
                  }}
                >
                  <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
                    <Box
                      component="img"
                      src={user}
                      sx={{ height: '18px', width: '18px' }}
                    />{' '}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        px: 1.1,
                        color: '#797979',
                        mt: -0.1,
                      }}
                    >
                      {profileData?.data?.data?.fullName}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Input
                  id="standard-basic"
                  variant="outlined"
                  {...register('fullName')}
                  // placeholder="John Doe"
                  disableUnderline
                  defaultValue={profileData?.data?.data?.fullName}
                  // placeholder={profileData?.data?.data?.fullName}
                  sx={EditInputStyle}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        src={user}
                        sx={{ height: '18px', width: '18px' }}
                      />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      color: '#797979',
                      fontSize: '14px',
                      padding: '7px',
                      paddingLeft: '6px', // Adjust the left padding to make room for the icon
                    },
                  }}
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={subText}>Email</Typography>
              {/* <Box
                component={"img"}
                src={edit}
                alt="img"
                onClick={() => {
                  setEditMode({ ...editMode, email: !editMode.email });
                }}
                sx={editDes}
              /> */}
            </Box>
            {!editMode.email ? (
              <>
                <Box
                  id="standard-basic"
                  variant="outlined"
                  disableUnderline
                  placeholder="example@site.com"
                  sx={{
                    ...EditInputStyle,
                    border: `2px solid ${
                      isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                    }`,
                  }}
                >
                  <Box sx={{ display: 'flex', mt: 1.3, gap: '5px' }}>
                    <Box
                      component="img"
                      src={sms}
                      sx={{ height: '18px', width: '18px' }}
                    />{' '}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        px: 1.1,
                        color: '#797979',
                        // mt: -0.1,
                      }}
                    >
                      {profileData?.data?.data?.email}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Input
                  id="standard-basic"
                  variant="outlined"
                  {...register('email')}
                  // disabled
                  disableUnderline
                  defaultValue={profileData?.data?.data?.email}
                  // placeholder={profileData?.data?.data?.email}
                  sx={EditInputStyle}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        src={sms}
                        sx={{ height: '18px', width: '18px' }}
                      />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      color: '#797979',
                      fontSize: '14px',
                      padding: '7px',
                      paddingLeft: '6px', // Adjust the left padding to make room for the icon
                    },
                  }}
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={subText}>Mobile</Typography>
              <Box
                component={'img'}
                onClick={() => {
                  setEditMode({ ...editMode, mobile: !editMode.mobile });
                }}
                src={edit}
                alt="img"
                sx={editDes}
              />
            </Box>
            {editMode.mobile ? (
              <>
                <Box
                  id="standard-basic"
                  variant="outlined"
                  disableUnderline
                  sx={{
                    ...EditInputStyle,
                    border: `2px solid ${
                      isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                    }`,
                  }}
                >
                  <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
                    <Box
                      component="img"
                      src={user}
                      sx={{ height: '18px', width: '18px' }}
                    />{' '}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        px: 1.1,
                        color: '#797979',
                        mt: -0.1,
                      }}
                    >
                      {profileData?.data?.data?.mobile}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Input
                  id="standard-basic"
                  variant="outlined"
                  {...register('mobile')}
                  disableUnderline
                  // placeholder={`+91 ${profileData?.data?.data?.mobile}`}
                  defaultValue={profileData?.data?.data?.mobile}
                  sx={EditInputStyle}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        src={user}
                        sx={{ height: '18px', width: '18px' }}
                      />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      color: 'black',
                      fontSize: '14px',
                      padding: '7px',
                      paddingLeft: '6px', // Adjust the left padding to make room for the icon
                    },
                  }}
                />
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', width: '100%', mx: 'auto', gap: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                mx: 'auto',
                mt: 1,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography sx={subText}>city</Typography>
              </Box>
              {editMode.city ? (
                <>
                  <Box
                    id="standard-basic"
                    variant="outlined"
                    disableUnderline
                    sx={{
                      ...EditInputStyle,
                      border: `2px solid ${
                        isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                      }`,
                    }}
                  >
                    <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
                      <Box
                        component="img"
                        src={cityimg}
                        sx={{ height: '18px', width: '18px' }}
                      />{' '}
                      <Typography
                        sx={{
                          fontSize: '14px',
                          px: 1.1,
                          color: '#797979',
                          mt: -0.1,
                        }}
                      >
                        {profileData?.data?.data?.city}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Input
                    id="standard-basic"
                    variant="outlined"
                    {...register('city')}
                    // placeholder="+91 | 9123567886 "
                    disableUnderline
                    disabled
                    defaultValue={profileData?.data?.data?.city}
                    // placeholder={profileData?.data?.data?.city}
                    sx={EditInputStyle}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          src={cityimg}
                          sx={{ height: '18px', width: '18px' }}
                        />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        color: '#797979',
                        fontSize: '14px',
                        padding: '7px',
                        paddingLeft: '6px', // Adjust the left padding to make room for the icon
                      },
                    }}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                mx: 'auto',
                mt: 1,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography sx={subText}>Profession</Typography>
              </Box>
              {editMode.profession ? (
                <>
                  <Box
                    id="standard-basic"
                    variant="outlined"
                    disableUnderline
                    sx={{
                      ...EditInputStyle,
                      border: `2px solid ${
                        isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                      }`,
                    }}
                  >
                    <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
                      <Box
                        component="img"
                        src={profimg}
                        sx={{ height: '18px', width: '18px' }}
                      />{' '}
                      <Typography
                        sx={{
                          fontSize: '14px',
                          px: 1.1,
                          color: '#797979',
                          mt: -0.1,
                        }}
                      >
                        {profileData?.data?.data?.profession}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Input
                    id="standard-basic"
                    variant="outlined"
                    {...register('profession')}
                    disableUnderline
                    disabled
                    defaultValue={profileData?.data?.data?.profession}
                    // placeholder={profileData?.data?.data?.profession}
                    sx={EditInputStyle}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          src={profimg}
                          sx={{ height: '18px', width: '18px' }}
                        />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        color: '#797979',
                        fontSize: '14px',
                        padding: '7px',
                        paddingLeft: '6px', // Adjust the left padding to make room for the icon
                      },
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 1,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography sx={subText}>Password</Typography>
            </Box>
            <Input
              id="standard-basic"
              variant="outlined"
              {...register("password")}
              // placeholder="*********"
              disableUnderline
              disabled
              defaultValue={profileData?.data?.data?.password}
              // placeholder={profileData?.data?.data?.fullName}
              sx={EditInputStyle}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component="img"
                    src={user}
                    sx={{ height: "18px", width: "18px" }}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordClick}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                style: {
                  color: "#797979",
                  fontSize: "14px",
                  padding: "7px",
                  paddingLeft: "6px", // Adjust the left padding to make room for the icon
                },
              }}
            />
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 0,
            }}
          >
            {profileData?.data?.data?.password ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    mx: 'auto',
                    mt: 1,
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={subText}>Password</Typography>
                    {/* <Box
                    component={"img"}
                    src={edit}
                    onClick={() => {
                      setEditMode({
                        ...editMode,
                        password: !editMode.password,
                      });
                    }}
                    alt="img"
                    sx={editDes}
                  /> */}
                  </Box>
                  {editMode.password ? (
                    <>
                      <Box
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        placeholder="*******"
                        sx={{
                          ...EditInputStyle,
                          border: `2px solid ${
                            isInputFocused ? '' : 'rgba(209, 209, 209, 1)'
                          }`,
                          scrollX: 'auto',
                          maxWidth: '100%',
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={lock}
                              sx={{ height: '18px', width: '18px' }}
                            />
                          </InputAdornment>
                        }
                      >
                        {' '}
                        <Box
                          sx={{
                            display: 'flex',
                            mt: 1.3,
                            gap: '5px',
                          }}
                        >
                          <Box
                            component="img"
                            src={lock}
                            sx={{ height: '18px', width: '18px' }}
                          />{' '}
                          <Typography
                            sx={{
                              fontSize: '14px',
                              px: 1.1,
                              color: '#797979',
                              mt: -0.1,
                              overflow: 'hidden', // Add this line to handle overflow
                              // textOverflow: 'ellipsis',
                            }}
                          >
                            {profileData?.data?.data?.password}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        type="password"
                        {...register('password')}
                        disabled
                        // placeholder="*******"
                        defaultValue={profileData?.data?.data?.password}
                        sx={{ ...EditInputStyle, scrollX: 'auto' }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={lock}
                              sx={{ height: '18px', width: '18px' }}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '7px',
                            paddingLeft: '6px', // Adjust the left padding to make room for the icon
                          },
                        }}
                      />
                    </>
                  )}
                </Box>
              </>
            ) : null}
          </Box>
          <Button sx={signInBtn} type="submit">
            Request Update
          </Button>
        </Box>
      </form>
    );
  };
  const batchDataRender = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          mx: 'auto',
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: { xl: '99%', lg: '99%', md: '98%', sm: '98%', xs: '96%' },
          }}
        >
          <Typography sx={{ ...subText, color: '#205DA1' }}>
            Batch list
          </Typography>
          <Button
            onClick={handleOpen}
            sx={{
              ...Styles.invoicebtn,
              borderRadius: '10px',
              boxShadow: 1,
              transition: 'transform 0.3s, box-shadow 0.3s',
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.main', boxShadow: 6 },
            }}
          >
            Add Batch{' '}
          </Button>
          {/* add batch name and question */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission
                batchSubmit(e);
              }}
            >
              <Box sx={modalStyle}>
                <Grid container>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={9}
                    sx={{
                      textAlign: 'end',
                    }}
                  >
                    {isEditing ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          textAlign: 'end',
                        }}
                      >
                        <Input
                          id="standard-basic"
                          variant="outlined"
                          disableUnderline
                          placeholder="Add Batch Name"
                          type="text"
                          value={batchName}
                          onChange={handleInputChangeEdit}
                          sx={{
                            width: 'auto',
                            height: '22px',
                            background: '#F4F5F6',
                            borderRadius: '5px',
                            fontSize: '12px',
                            color: '#445FD2',
                            // px: 1,
                            border: 'none',
                            '&::placeholder': {
                              color: '#445FD2', // Change the color here
                            },
                          }}
                          inputProps={{
                            style: {
                              color: '#797979',
                              fontSize: '14px',
                              padding: '7px',
                              paddingLeft: '10px', // Adjust the left padding to make room for the icon
                            },
                          }}
                        />
                        <Button
                          onClick={handleSaveClick}
                          sx={{
                            ml: { xl: 2, lg: 2, md: 0, sm: 0, xs: 0 },
                            fontSize: '10px',
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            alignSelf: 'flex-end',
                            mr: { xl: 10, lg: 10, md: 5, sm: 2, xs: 2 },
                          }}
                        >
                          <Box
                            onClick={handleEditClick}
                            sx={{
                              // background: 'grey',
                              padding: '0px',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography sx={batchNameText}>
                              {batchName}
                            </Typography>
                          </Box>
                          <img
                            src={pen}
                            alt=""
                            style={{
                              height: '15px',
                              width: '15px',
                              cursor: 'pointer',
                            }}
                            onClick={handleEditClick}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={3}
                    sx={{
                      textAlign: 'end',
                    }}
                  >
                    <Box
                      onClick={handleClose}
                      component={'img'}
                      src={closeicon}
                      alt=""
                      sx={{
                        height: 'auto',
                        width: {
                          xl: '5%',
                          lg: '5%',
                          md: '5%',
                          sm: '15%',
                          xs: '30%',
                        },
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        ml: 'auto',
                        alignSelf: 'flex-end',
                        mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      mt: { xl: 0, lg: 0, md: 0.5, sm: 0.5, xs: 0.5 },
                    }}
                  >
                    <Typography sx={subTextProfile}>
                      Make a list of the questions you would like to ask in
                      pdfs.
                    </Typography>
                  </Box>
                </Grid>
                <Box
                  sx={{
                    mt: { xl: 4, lg: 4, md: 3, sm: 2, xs: 1 },
                    width: '90%',
                    mx: 'auto',
                  }}
                >
                  <Typography sx={que}>Question</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                      mt: 1,
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      placeholder="Add Batch Name"
                      value={inputText}
                      onChange={handleInputChange}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault(); // Prevent form submission
                          handleAddItem(); // Call the function to add the question instead
                        }
                      }}
                      sx={{
                        width: 'auto',
                        height: {
                          xl: '42px',
                          lg: '42px',
                          md: '36px',
                          sm: '30px',
                          xs: '30px',
                        },
                        background: '#F4F5F6',
                        borderRadius: {
                          xl: '10px',
                          lg: '10px',
                          md: '9px',
                          sm: '8px',
                          xs: '8px',
                        },
                        fontSize: {
                          xl: '12px',
                          lg: '12px',
                          md: '10px',
                          sm: '9px',
                          xs: '8px',
                        },
                        color: '#445FD2',
                        // px: 1,
                        border: 'none',
                        '&::placeholder': {
                          color: '#445FD2', // Change the color here
                        },
                      }}
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '7px',
                          paddingLeft: '10px', // Adjust the left padding to make room for the icon
                        },
                      }}
                    />
                  </Box>
                  <Button
                    onClick={handleAddItem}
                    sx={{
                      ...signInBtn,
                      width: '100%',
                      mt: 1,
                      p: { xl: 1.3, lg: 1.3, md: 1.1, sm: 1, xs: 1 },
                      // '&:hover': { background: 'rgba(1, 259, 352,1)' },
                    }}
                  >
                    Add Question
                  </Button>
                  <Box sx={{ mt: 3 }}>
                    <Typography sx={que}>Added Question</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      maxHeight: '100px',
                      // overflowY: 'scroll',
                    }}
                  >
                    {todoList?.length === 0 ? (
                      <Typography sx={subTextProfile}>
                        {' '}
                        No question added yet
                      </Typography>
                    ) : (
                      <>
                        {todoList.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              maxHeight: '100px',
                              mt: 0.5,
                            }}
                          >
                            <Typography sx={{ ...que, fontWeight: 500 }}>
                              {index + 1}. {item}{' '}
                            </Typography>
                            <Box
                              onClick={() => handleDeleteItemBatch()}
                              component={'img'}
                              src={trash}
                              sx={{
                                height: 'auto',
                                width: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </Box>
                        ))}
                      </>
                    )}
                  </Box>
                  <Button
                    type="submit"
                    sx={{
                      ...signInBtn,
                      bottom: 2,
                      width: {
                        xl: '60%',
                        lg: '60%',
                        md: '70%',
                        sm: '80%',
                        xs: '80%',
                      },
                    }}
                  >
                    Create Batch
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        </Box>
        <Box
          sx={{
            mt: 2,
            position: 'relative',
            overflowY: 'auto',
            maxHeight: '350px', // Enables smooth scrolling on iOS devices
            maxHeight: '350px',
            '::-webkit-scrollbar': {
              width: '3px',
              display: 'flex',
            },
            '::-webkit-scrollbar-thumb': {
              // 68858C
              background: 'rgba(9, 26, 50, 1)',
              borderRadius: '3px',
            },
            '::-webkit-scrollbar-thumb:vertical': {
              maxHeight: '2px',
              minHeight: '1px',
            },
            '::-webkit-scrollbar-thumb:horizontal': {
              minWidth: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: 'rgba(180, 180, 180, 1)' /* Color of the track */,
            },
          }}
        >
          <Modal
            open={openEditDelete}
            onClose={handleCloseEditDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <form onSubmit={batchUpdateSubmit}>
              <Box
                sx={{
                  ...modalStyle,
                  width: {
                    xl: '60%',
                    lg: '60%',
                    md: '60%',
                    sm: '70%',
                    xs: '80%',
                  },
                }}
              >
                <Box
                  onClick={handleCloseEditDelete}
                  component={'img'}
                  src={closeicon}
                  alt=""
                  sx={{
                    height: 'auto',
                    width: { xl: '3%', lg: '3%', md: '4%', sm: '5%', xs: '7%' },
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    ml: 'auto',
                    mt: 0,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      mx: 'auto',
                      gap: '10px',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      placeholder="Ask Anything"
                      type="text"
                      value={editingItem?.name}
                      onChange={handleInputChangeEdit}
                      readOnly={!isEditing}
                      sx={{
                        width: 'auto',
                        height: '22px',
                        background: '#F4F5F6',
                        borderRadius: '5px',
                        fontSize: '12px',
                        color: '#445FD2',
                        border: 'none',
                        '&::placeholder': {
                          color: '#445FD2',
                        },
                      }}
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '7px',
                          paddingLeft: '10px',
                        },
                      }}
                    />
                    {isEditing ? (
                      <Button onClick={handleSaveClick} sx={{ ml: 1 }}>
                        Save
                      </Button>
                    ) : (
                      <Box
                        onClick={handleEditClick}
                        component={'img'}
                        src={pen}
                        alt="Edit"
                        sx={{
                          height: '20px',
                          width: '20px',
                          cursor: 'pointer',
                          ml: 1,
                        }}
                      />
                    )}
                  </Box>
                  {getQuestions?.data?.questions?.map((item, index) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '80%',
                          mx: 'auto',
                        }}
                      >
                        <Box
                          sx={{
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {index + 1}. {item}
                        </Box>
                        <Box
                          onClick={() =>
                            handleDeleteItemBatchQuestion(
                              index,
                              item,
                              editingItem?._id
                            )
                          }
                          component={'img'}
                          src={trash}
                          sx={{
                            maxHeight: '20px',
                            maxWidth: '15px',
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                    );
                  })}
                  <Button type="submit" sx={signInBtn}>
                    Update
                  </Button>
                  {/**/}
                </Box>
              </Box>
            </form>
          </Modal>
          {batchList?.data?.data?.map((item, index) => {
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    mt: 2,
                    justifyContent: 'space-between',
                    mb: 0,
                    // p: 1,
                  }}
                  key={item._id}
                >
                  <Box sx={{ display: 'flex', gap: '15px', mb: 2 }}>
                    <Box
                      component={'img'}
                      src={question}
                      sx={{
                        height: 'auto',
                        width: 'auto',
                        maxWidth: '30px',
                        mt: 0.5,
                      }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{
                          ...mainText,
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography sx={timeText}>
                        {item?.updatedAt
                          ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            }).format(new Date(item.updatedAt))
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <MoreVertIcon
                    sx={{ mt: 1, cursor: 'pointer' }}
                    onClick={handleOpenEditDelete}
                  /> */}
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openList ? 'long-menu' : undefined}
                    aria-expanded={openList ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => handleClickEdit(e, item)}
                    sx={{
                      '&:hover': { background: '#fff', fontWeight: 800 },
                    }}
                  >
                    <MoreVertIcon sx={{}} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={openList}
                    onClose={handleCloseEdit}
                    PaperProps={{
                      style: {
                        maxHeight: '100px',
                        width: '19ch',
                        boxShadow: '0px 1px 3px #00000029',
                        border: '1px solid  rgba(241, 243, 246, 1)',
                      },
                    }}
                  >
                    {/* {options.map((option) => ( */}
                    <MenuItem
                      // key={option}
                      // selected={option === 'Pyxis'}
                      // onClick={handleCloseEdit}
                      // onClick={() => {handleOpenEditDelete(item?._id)}}
                      onClick={() => {
                        handleOpenEditDelete(editingItem?._id);
                        handleCloseEdit();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      // key={option}
                      // selected={option === 'Pyxis'}
                      // onClick={(index) => {
                      //   const newArray = [...PdfStaticarray];
                      //   newArray.splice(index, 1);
                      //   setPdfStaticarray(newArray);
                      //   handleCloseEdit();
                      // }}
                      onClick={() => {
                        handleDeleteItem(editingItem?._id);
                        handleCloseEdit();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Box>
                <Box
                  sx={{
                    width: '99%',
                    borderBottom: '1px solid rgba(208, 213, 221, 0.3)',
                  }}
                ></Box>
              </>
            );
          })}
          <Dialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this batch list item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmDialog(false)}>
                Cancel
              </Button>
              <Button
                onClick={confirmDelete}
                sx={{
                  background: 'red',
                  color: '#fff',
                  '&:hover': { background: 'red' },
                }}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    );
  };
  const planDataRender = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          mt: 2,
        }}
      >
        {/* <QRCodeStaticScanner /> */}
        <Typography sx={profileName}>coming soon...</Typography>
        {/* <Typography sx={profileName}>Your Membership</Typography>
        <Box sx={planBox}>
          <Grid container sx={{ width: '95%', mx: 'auto' }}>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
              <Box>
                <Typography sx={plantext}>Plan : Free Plan</Typography>
                <Typography sx={subplantext}>Rs. 0 /month</Typography>
              </Box>
              <Box mt={4}>
                <Typography sx={plantext}>Membership Service</Typography>
                <Typography sx={subplantext}>
                  02 June 2024 - 02 July 2024{' '}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Box>
                <Typography sx={plantext}>Next Billing Date</Typography>
                <Typography sx={subplantext}>2 July 2024</Typography>
              </Box>
              <Box mt={4}>
                <Typography sx={plantext}>Billing Information</Typography>
                <Button
                  sx={{
                    ...Styles.invoicebtn,
                    mt: 1,
                    backgroundColor: 'secondary.main',
                    borderRadius: '10px',
                    boxShadow: 1,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      boxShadow: 5,
                    },
                  }}
                >
                  View Invoice{' '}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Typography sx={profileName}>
            Upgrade Your Membership Here !
          </Typography>
          <Typography
            sx={{
              ...subplantext,
              textAlign: 'center',
              color: 'rgba(28, 28, 28, 1)',
            }}
          >
            Lorem ipsum dolor sit amet consectetur. Consequat egestas mattis sed
            urna mi.
          </Typography>
          <Button
            sx={{
              ...Styles.invoicebtn,
              p: 1,
              mt: 5,
              backgroundColor: 'secondary.main',
              borderRadius: '10px',
              boxShadow: 1,
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                backgroundColor: 'secondary.main',
                boxShadow: 5,
              },
            }}
            onClick={() => {
              navigate('/plan');
            }}
          >
            Upgrade Membership
          </Button>
        </Box> */}
      </Box>
    );
  };
  const walletDataRender = () => {
    return (
      <>
        <Box
          sx={{
            border: '1px solid rgba(206, 206, 206, 1)',
            borderRadius: '10px',
            p: 2,
            px: 2,
            mt: 3,
            width: '90%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ml: { xl: 'auto', lg: 'auto', md: 'auto', sm: -0.5, xs: -0.5 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Box
              component={'img'}
              src={walletimg}
              alt=""
              sx={{ maxWidth: '40px', height: 'auto' }}
            />
            <Box>
              <Typography sx={{ ...Styles.boxSubText, fontWeight: 600 }}>
                Main Balance
              </Typography>
              <Typography sx={Styles.boxMainText}>
                {walletData?.data?.at(0)?.balance} coins
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: {
                xl: '100px',
                lg: '100px',
                md: '100px',
                sm: '20px',
                xs: '20px',
              },
            }}
          >
            <Box>
              <Typography sx={{ ...Styles.boxSubText, fontWeight: 600 }}>
                Membership plan
              </Typography>
              <Typography sx={Styles.boxSubText}>
                {' '}
                {walletData?.data?.at(0)?.planType}{' '}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ ...Styles.boxSubText, fontWeight: 600 }}>
                Membership Service
              </Typography>
              <Typography sx={Styles.boxSubText}>
                {new Date(
                  walletData?.data?.at(0)?.planStartDate
                ).toLocaleDateString('en-GB')}{' '}
                -{' '}
                {new Date(
                  walletData?.data?.at(0)?.planEndDate
                ).toLocaleDateString('en-GB')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '97%', overflowX: 'auto', mt: 4, mx: 'auto' }}>
          <Box sx={{ width: { xs: '600px', sm: '95%' }, mx: 'auto' }}>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Box
                onClick={() => setTab(0)}
                sx={{
                  ...Styles.tabText,
                  background: tab === 0 ? 'rgba(9, 26, 50, 1)' : 'transperent',
                  color: tab === 0 ? '#fff' : 'transperent',
                }}
              >
                Wallet History
              </Box>
              <Box
                onClick={() => setTab(1)}
                sx={{
                  ...Styles.tabText,
                  background: tab === 1 ? 'rgba(9, 26, 50, 1)' : 'transperent',
                  color: tab === 1 ? '#fff' : 'transperent',
                }}
              >
                Membership History
              </Box>
            </Box>
            {tab === 0 && (
              <>
                <Grid
                  container
                  mt={2}
                  sx={{
                    borderBottom: '1.5px solid rgba(230, 233, 245, 1)',
                    py: 1,
                  }}
                >
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>Date</Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>Queries</Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>
                      Total Cost
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    maxHeight: {
                      xl: '250px',
                      lg: '250px',
                      md: '250px',
                      sm: '200px',
                      xs: '200px',
                    },
                    overflowY: 'auto',
                    width: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  {walletHistoryData?.data?.map((item, index) => (
                    <>
                      <div key={index}>
                        <Box
                          sx={{
                            border: '1px solid rgba(230, 233, 245, 1)',
                            borderRadius: '10px 10px 10px 10px',
                            width: '99.9%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            py: 1,
                            mb: 2,
                            mt: 0.5,
                          }}
                        >
                          <Grid container>
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...Styles.gridheaderSubText,
                                    fontWeight: 700,
                                  }}
                                >
                                  {new Date(item?.date).toLocaleDateString(
                                    'en-GB'
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              <Typography
                                sx={Styles.gridheaderSubText}
                              ></Typography>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              <Typography
                                sx={Styles.gridheaderSubText}
                              ></Typography>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                              <Typography sx={Styles.gridheaderSubText}>
                                {item?.count}
                              </Typography>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              <Typography
                                sx={Styles.gridheaderSubText}
                              ></Typography>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                              <Typography sx={Styles.gridheaderSubText}>
                                {item?.totalBalance} coins
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </>
                  ))}
                </Box>
              </>
            )}
            {tab === 1 && (
              <>
                <Grid
                  container
                  mt={2}
                  sx={{
                    borderBottom: '1.5px solid rgba(230, 233, 245, 1)',
                    py: 1,
                  }}
                >
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>
                      Plan Name
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>
                      Service Date
                    </Typography>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                    <Typography sx={Styles.gridheaderText}></Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Typography sx={Styles.gridheaderText}>
                      Total Cost
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    maxHeight: {
                      xl: '250px',
                      lg: '250px',
                      md: '250px',
                      sm: '200px',
                      xs: '200px',
                    },
                    overflowY: 'auto',
                    width: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  {walletData?.data[0]?.userData?.planHistory?.map(
                    (item, index) => (
                      <>
                        <div key={index}>
                          <Box
                            sx={{
                              border: '1px solid rgba(230, 233, 245, 1)',
                              borderRadius: '10px 10px 10px 10px',
                              width: '99.9%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              py: 1,
                              mb: 2,
                              mt: 0.5,
                            }}
                          >
                            <Grid container>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...Styles.gridheaderSubText,
                                      fontWeight: 700,
                                    }}
                                  >
                                    {item?.planName}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                <Typography
                                  sx={Styles.gridheaderSubText}
                                ></Typography>
                              </Grid>
                              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                <Typography
                                  sx={Styles.gridheaderSubText}
                                ></Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                <Typography sx={Styles.gridheaderSubText}>
                                  {new Date(
                                    item?.planStartDate
                                  ).toLocaleDateString('en-GB')}{' '}
                                  -{' '}
                                  {new Date(
                                    item?.planEndDate
                                  ).toLocaleDateString('en-GB')}
                                </Typography>
                              </Grid>
                              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                <Typography
                                  sx={Styles.gridheaderSubText}
                                ></Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                <Typography sx={Styles.gridheaderSubText}>
                                  {item?.planPrice} $
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      </>
                    )
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </>
    );
  };
  const settingDataRender = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: { xl: '65%', lg: '65%', md: '65%', sm: '95%', xs: '95%' },
          mx: 'auto',
          mt: 5,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <Box>
          <Typography sx={{ ...profileName, textAlign: 'left' }}>
            Email Notification
          </Typography>
          <Typography sx={Styles.subemailtext}>
            Toggle on/off whether you want email updates for our daily news and
            articles and for our website updates
          </Typography>
        </Box>
        <Switch
          {...label}
          defaultChecked
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: 'white',
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: 'green',
            },
            '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track': {
              backgroundColor: 'red',
            },
            '& .MuiSwitch-track': {
              borderRadius: 20 / 2,
            },
          }}
        />
      </Box>
    );
  };
  return (
    <Paper elevation={0} sx={Styles.PaperDesOther}>
      <Header />
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            textAlign: 'center',
            zIndex: 10,
            display: {
              xl: 'inline',
              lg: 'inline',
              md: 'inline',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'center',
              position: 'fixed',
              left: '5%',
              top: '15%',
            }}
          >
            <Avatar {...stringAvatar(profileData?.data?.data?.fullName)} />
          </Box>
          <Box sx={{ position: 'fixed', left: '10%', top: '9%' }}>
            <Typography sx={{ ...profileName, textAlign: 'left' }}>
              {profileData?.data?.data?.fullName}
            </Typography>
            <Typography sx={profileMail}>
              {profileData?.data?.data?.email}
            </Typography>
          </Box>
        </Grid>
        {/* // desktop size */}
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mt={10}
          sx={{
            background: 'red',
            display: {
              xl: 'inline',
              lg: 'inline',
              md: 'inline',
              sm: 'inline',
              xs: 'inline',
            },
            position: 'fixed',
          }}
        >
          <Paper
            elevation={2}
            sx={{
              width: '100%',
              height: '80%',
              mx: 'auto',
              p: 1,
              position: 'fixed',
              bottom: 0,
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Tabs
                className="page-filters-tabs"
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ mt: 0.5 }}
              >
                <Tab
                  key="Active"
                  onClick={() => {
                    setDataTypes('UpdateProfile');
                    //   setCurrentPage(1);
                  }}
                  disableRipple
                  sx={tabDesign}
                  label="Update Profile"
                />
                <Tab
                  key="Inactive"
                  onClick={() => {
                    setDataTypes('Batch');
                  }}
                  sx={tabDesign}
                  label="Batch"
                />
                <Tab
                  key="Inactive"
                  onClick={() => {
                    setDataTypes('Plans');
                  }}
                  sx={tabDesign}
                  label="Plans"
                />
                {/* <Tab
                  key="Inactive"
                  onClick={() => {
                    setDataTypes('history');
                  }}
                  sx={tabDesign}
                  label="Wallet"
                /> */}
                {/* <Tab
                  key="Inactive"
                  onClick={() => {
                    setDataTypes("Settings");
                  }}
                  sx={tabDesign}
                  label="Settings"
                /> */}
              </Tabs>
            </Box>
            {activeTab === 0 && <div>{profileDataRender()}</div>}
            {activeTab === 1 && <div>{batchDataRender()}</div>}
            {activeTab === 2 && <div>{planDataRender()}</div>}
            {/* {activeTab === 3 && <div>{walletDataRender()}</div>} */}
            {/* {activeTab === 4 && <div>{settingDataRender()}</div>} */}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Profile;

const profileName = {
  color: '#000',
  fontFamily: 'Lato',
  fontSize: { xl: '22px', lg: '22px', md: '20px', sm: '15px', xs: '15px' },
  fontStyle: 'normal',
  fontWeight: 700,
  textAlign: 'center',
};

const profileMail = {
  color: 'rgba(0, 0, 0, 0.50)',
  fontFamily: 'Lato',
  fontSize: { xl: '15px', lg: '15px', md: '10px', sm: '8px', xs: '8px' },
  fontStyle: 'normal',
  fontWeight: 400,
};

const subText = {
  color: '#000',
  fontFamily: 'Lato',
  fontSize: {
    xl: '0.8rem',
    lg: '0.8rem',
    md: '0.8rem',
    sm: '0.7rem',
    xs: '0.7rem',
  },
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  mr: 'auto',
};
const signInBtn = {
  borderRadius: {
    xl: '10px',
    lg: '10px',
    md: '9px',
    sm: '8px',
    xs: '8px',
  },
  backgroundColor: 'secondary.main',
  color: '#FFF',
  fontFamily: 'Lato',
  fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '0.6rem', xs: '0.6rem' },
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'none',
  p: { xl: 1.5, lg: 1.5, md: 1.5, sm: 1.5, xs: 1.5 },
  mt: { xl: 4, lg: 3, md: 3, sm: 3, xs: 3 },
  width: '40%',
  mx: 'auto',
  textAlign: 'center',
  display: 'flex',
  justifcontent: 'center',
  transition: 'background 1s, color 1s , box-shadow 0.5s',
  '&:hover ': {
    backgroundColor: 'primary.main',
    color: '#fff',
    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.25)',
  },
};

const tabDesign = {
  '&.Mui-selected': {
    color: '#fff',
    borderRadius: 10,
    backgroundColor: 'secondary.main',
    fontWeight: 700,
  },
  textTransform: 'capitalize',
  color: '#000',
  fontFamily: 'Lato',
  fontSize: {
    xl: '0.8rem',
    lg: '0.8rem',
    md: '0.7rem',
    sm: '0.5rem',
    xs: '0.5rem',
  },
  fontStyle: 'normal',
  fontWeight: 400,
  borderRadius: '10px !important',
  minHeight: '0 !important',
  // padding: {
  //   xl: '15px 15px 15px 15px',
  //   lg: '15px 15px 15px 15px',
  //   md: '15px 9px 15px 9px',
  //   sm: '9px 5px 9px 5px',
  //   xs: '9px 5px 9px 5px',
  // },
  width: { xl: '140px', lg: '140px', md: '120px', sm: '100px', xs: '80px' },
  height: { xl: '38px', lg: '38px', md: '38px', sm: '30px', xs: '30px' },
  '&:hover': {
    color: 'primary.main',
  },
};

const mainText = {
  color: '#242634',
  fontFamily: 'Lato',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontStyle: 'normal',
  fontWeight: 400,
};
const timeText = {
  color: 'var(--Grey-05, #242634)',
  fontFamily: 'Lato',
  fontSize: { xl: '12px', lg: '12px', md: '12px', sm: '10px', xs: '10px' },
  fontStyle: 'normal',
  fontWeight: 400,
};

const batchText = {
  color: '#191919',
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
};

const subTextProfile = {
  color: 'rgba(71, 84, 103, 0.81)',
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: { xl: '14px', lg: '14px', md: '12px', sm: '9px', xs: '9px' },
  fontStyle: 'normal',
  fontWeight: 400,
  Text: 'center',
};

const que = {
  color: 'var(--Grey-05, #242634)',
  fontFamily: 'Lato',
  fontSize: { xl: '14px', lg: '14px', md: '12px', sm: '10px', xs: '10px' },
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
};

const batchNameText = {
  color: '#191919',
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: { xl: '18px', lg: '18px', md: '14px', sm: '12px', xs: '12px' },
  fontStyle: 'normal',
  fontWeight: 600,
};
const EditInputStyle = {
  width: 'auto',
  height: {
    xl: '35px',
    lg: '35px',
    md: '35px',
    sm: '35px',
    xs: '35px',
  },

  borderRadius: {
    xl: '10px',
    lg: '10px',
    md: '10px',
    sm: '8px',
    xs: '8px',
  },
  background: '#FFFFFF',
  fontSize: '12px',
  color: '#797979',
  px: 1,
  mt: 1,
  border: '1px solid rgba(209, 209, 209, 1)',
  '&::placeholder': {
    color: '#797979', // Change the color here
  },
};

const editDes = {
  height: 'auto',
  width: {
    xl: '20px',
    lg: '20px',
    md: '20px',
    sm: '15px',
    xs: '15px',
  },
  cursor: 'pointer',
};

const planBox = {
  mt: 2,
  border: '1px solid rgba(206, 206, 206, 1)',
  borderRadius: '5px',
  width: { xl: '40%', lg: '40%', md: '60%', sm: '85%', xs: '85%' },
  height: 'auto',
  p: 2,
  mx: 'auto',
};

const plantext = {
  fontFamily: 'Lato',
  fontSize: {
    xl: '1rem',
    lg: '1rem',
    md: '1rem',
    sm: '0.7rem',
    xs: '0.7rem',
  },
  fontWeight: 600,
  textAlign: 'left',
  color: 'rgba(37, 36, 48, 1)',
  lineHeight: '28px',
};

const subplantext = {
  fontFamily: 'Lato',
  fontSize: {
    xl: '1rem',
    lg: '1rem',
    md: '1rem',
    sm: '0.6rem',
    xs: '0.6rem',
  },
  fontWeight: 400,
  color: 'rgba(3, 2, 41, 1)',
};
