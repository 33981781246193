import React from 'react';
import { Paper, Box, Typography, Grid, Button, TextField } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaDiscord } from 'react-icons/fa';
import * as Styles from '../../Common/Styles.js';
import paramainlogo from '../../Assets/Header/paralogo.svg';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
const Footer = () => {
  const navigate = useNavigate();
  return (
    <Paper elevation={0}>
      <Box sx={Styles.footerMainSection}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
          }}
        >
          <Box sx={setFooterBox}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                flex: '1 1 50%',
                height: '100%',
                // background: "yellow",
                justifyContent: 'center',
              }}
            >
              <Box
                component={'img'}
                src={paramainlogo}
                alt=""
                sx={{
                  height: 'auto',
                  maxWidth: {
                    xl: '40px',
                    lg: '40px',
                    md: '30px',
                    sm: '20px',
                    xs: '20px',
                  },
                }}
              />
              <Typography sx={{ ...Styles.forgetText, fontWeight: 700 }}>
                PARA
              </Typography>
            </Box>
            <Box
              sx={{
                flex: '1 1 50%',
                // background: "red",
              }}
            >
              <Typography sx={Styles.footerText}>
                Unada LexiLegal AI India Private Limited 550, Iscon Emporio,
                Jodhpur Char Rasta, Ahmedabad - 380015
              </Typography>
              <Typography sx={{ ...Styles.footerText, mt: 1 }}>
                business@parasors.in
              </Typography>
              {/* <Typography sx={Styles.footerText}>510-365-2019</Typography> */}
            </Box>
          </Box>
          <Box
            sx={{
              borderRight: '1px solid rgba(30, 40, 51, 0.07)',
              height: '150px',
              display: {
                xl: 'inline',
                lg: 'inline',
                md: 'inline',
                sm: 'none',
                xs: 'none',
              },
            }}
          />
          <Box sx={footersecondBox}>
            <Box>
              {/* <Typography sx={Styles.footercolor}>Services</Typography> */}
              {/* <Link to="/services" style={{ textDecoration: 'none' }}> */}
              <Typography
                sx={Styles.footersubText}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate('/services');
                }}
              >
                Services
              </Typography>
              {/* </Link> */}
              <Link to="/content" style={{ textDecoration: 'none' }}>
                <Typography sx={Styles.footersubText}>
                  Content Coverage
                </Typography>
              </Link>
              <Link to="/plan" style={{ textDecoration: 'none' }}>
                <Typography sx={Styles.footersubText}>Pricing </Typography>
              </Link>
            </Box>
            <Box>
              <Link to="/Privacypolicy" style={{ textDecoration: 'none' }}>
                <Typography sx={Styles.footersubText}>
                  {' '}
                  Privacy Policy
                </Typography>
              </Link>
              <Link to="/faq" style={{ textDecoration: 'none' }}>
                <Typography sx={Styles.footersubText}>FAQs</Typography>
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate('/termsandcondition');
                }}
                style={{ textDecoration: 'none' }}
              >
                <Typography sx={Styles.footersubText}>T&C </Typography>
              </Link>
            </Box>
            <Box>
              <Typography sx={{ ...Styles.footercolor }}>Follow Us</Typography>
              <Box sx={{ display: 'flex', gap: '10px', py: 2 }}>
                <Box
                  component={'a'}
                  href="https://www.instagram.com/paraprobot.ai?igsh=eWtvb3gxdWRicXhu"
                  target="_blank"
                  sx={{ textDecoration: 'none' }}
                >
                  <InstagramIcon
                    style={{
                      height: '20px',
                      cursor: 'pointer',
                      color: '#000',
                    }}
                  />
                </Box>
                <Box
                  component={'a'}
                  href="https://www.linkedin.com/company/para-ai-paralegal/"
                  target="_blank"
                  sx={{ textDecoration: 'none' }}
                >
                  <LinkedInIcon
                    sx={{
                      height: '20px',
                      cursor: 'pointer',
                      color: '#000',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{ ...Styles.subheadingtabletext, textAlign: 'center', py: 2 }}
      >
        Copyright © 2024. PARA. All rights reserved.
      </Typography>
    </Paper>
  );
};

export default Footer;

const setFooterBox = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: { xl: '60%', lg: '60%', md: '60%', sm: '100%', xs: '100%' },
  gap: '10px',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
};

const footersecondBox = {
  display: 'flex',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
  justifyContent: 'space-around',
  width: {
    xl: '60%',
    lg: '60%',
    md: '60%',
    sm: '100%',
    xs: '100%',
  },
  mx: 'auto',
  alignItems: 'center',
};
